<template>
    <div class="container">
      <div class="content">
        <h2 v-if="!connectedAccountId">Get ready for take off</h2>
        <p v-if="!connectedAccountId">Rocket Rides is the world's leading air travel platform: join our team of pilots to help people travel faster.</p>
        <div v-if="!accountCreatePending && !connectedAccountId">
          <button @click="createAccount">Start getting paid</button>
        </div>
        <div id="embedded-onboarding-container" />
        <p v-if="error" class="error">Something went wrong!</p>
        <div v-if="connectedAccountId || accountCreatePending" class="dev-callout">
         <!--  <p v-if="connectedAccountId">Your connected account ID is: <code class="bold">{{ connectedAccountId }}</code></p> -->
          <p v-if="accountCreatePending">Creating a payment account...</p>
          <p v-if="onboardingExited">The Account Onboarding component has exited</p>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import { loadConnectAndInitialize } from '@stripe/connect-js'
  
  export default {
    data() {
      return {
        accountCreatePending: false,
        onboardingExited: false,
        error: false,
        connectedAccountId: null,
        stripeConnectInstance: null,
      };
    },
    methods: {
      createAccount() {
        this.accountCreatePending = true;
        this.error = false;
        fetch( `${process.env.VUE_APP_API_URL}/stripe/affiliates/account`, {
          method: "POST",
          credentials: "same-origin",
        }).then((response) =>
          response.json().then((json) => {
            this.accountCreatePending = false;
            this.connectedAccountId = json.account;
//TODO: UPDATE DB WITH ACCOUNTID


            const fetchClientSecret = async () => {
              const response = await fetch(`${process.env.VUE_APP_API_URL}/stripe/affiliates/account_session`, {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                },
                body: JSON.stringify({
                  account: this.connectedAccountId,
                }),
                credentials: "same-origin",
              });
  
              if (!response.ok) {
                // Handle errors on the client side here
                const { error } = await response.json();
                throw ("An error occurred: ", error);
              } else {
                console.log( response.json());
                const { client_secret: clientSecret } = await response.json();
                return clientSecret;
              }
            };
            this.stripeConnectInstance = loadConnectAndInitialize({
              publishableKey: process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY,
              fetchClientSecret,
              appearance: {
                overlays: "dialog",
                variables: {
                  colorPrimary: "#0e3d4d",
                },
              },
            });
  
            const container = document.getElementById("embedded-onboarding-container");
            const embeddedOnboardingComponent = this.stripeConnectInstance.create("account-onboarding");
            embeddedOnboardingComponent.setOnExit(() => {
              console.log('User exited the onboarding flow');
            });
            container.appendChild(embeddedOnboardingComponent);
          })
        );
      },
    },
  };
  </script>

  <style lang="scss" scoped>
  * {
    box-sizing: border-box;
  }
  
  #root {
    width: 100%;
  }
  
  body {
    font-family: -apple-system, BlinkMacSystemFont, sans-serif;
    -webkit-font-smoothing: antialiased;
    margin-top: 0;
    margin-bottom: 80px;
  }
  
  h2 {
    margin-bottom: 0;
    font-size: 20px;
  }
  
  p {
    margin: 0;
    color: #687385;
    padding: 8px 0;
  }
  
  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .content {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    width: 420px;
    padding-top: 40px;
    margin-bottom: 110px;
  }
  .dev-callout {
    position: fixed;
    bottom: 110px;
    border-radius: 4px;
    box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.12), 0px 15px 35px 0px rgba(48, 49, 61, 0.08);
    padding: 9px 12px;
    background-color: #ffffff;
  }
  
  .bold {
    font-weight: 700;
    font-size: 14px;
  }
  
  .error {
    font-weight: 400;
    color: #E61947;
    font-size: 14px;
  }
  
  .divider {
    width: 100%;
    height: 1px;
    margin: 24px 24px;
    border-top: 1px solid #D8DEE4;
  }
  
  button {
    font-family: -apple-system, BlinkMacSystemFont, sans-serif;
    font-size: 16px;
    font-weight: 600;
    border: 1px solid gray;
    border-radius: 4px;
    margin-top: 32px;
    padding-top: 10px;
    padding-bottom: 10px;
    background-color: #0e3d4d;
    color: #ffffff;
    width: 420px;
  }
  
  button:hover {
    border-color: black;
  }
</style>